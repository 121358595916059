import HoverImage from '@/components/cms/HoverImage';

export default function HoverImageWrapper({ blok }) {
  const { image, fullWidth, hoverImage, width, height, backgroundColor, borderRadius, quality } =
    blok;

  if (image.filename && hoverImage.filename) {
    return (
      <HoverImage
        fullWidth={fullWidth}
        backgroundColor={backgroundColor}
        image={image.filename}
        imageHover={hoverImage.filename}
        width={width}
        height={height}
        borderRadius={borderRadius}
        quality={quality}
      />
    );
  }

  return null;
}
