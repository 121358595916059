import clsx from 'clsx';

import { SITE_URL, STORYBLOK_IMAGE_QUALITY } from '@/lib/constants';

import styles from './HoverImage.module.scss';

const inDev = process.env.NODE_ENV === 'development';
const host = `${SITE_URL}cdn/`;

const HoverImage = ({
  backgroundColor = 'transparent',
  image,
  imageHover,
  fullWidth = false,
  width,
  borderRadius,
  quality,
  height
}) => {
  const imageSrc = inDev ? image : image.replace('https://a.storyblok.com/', host);
  const imageHoverSrc = inDev ? imageHover : imageHover.replace('https://a.storyblok.com/', host);

  const imageIsSVG = image.endsWith('.svg');
  const hoverImageIsSVG = imageHover.endsWith('.svg');

  const imageSrcWithQuality = `${imageSrc}/m/${
    quality === '100' ? '2640x0/' : '1320x0/'
  }filters:quality(${STORYBLOK_IMAGE_QUALITY})`;
  const imageHoverSrcWithQuality = `${imageHoverSrc}/m/${
    quality === '100' ? '2640x0/' : '1320x0/'
  }filters:quality(${STORYBLOK_IMAGE_QUALITY})`;

  return (
    <div
      className={clsx(styles.hoverImage, borderRadius && styles[`borderRadius--${borderRadius}`])}
      style={{
        ...(backgroundColor && { backgroundColor }),
        width: fullWidth ? '100%' : 'auto'
      }}
    >
      <img
        width={width}
        height={height}
        src={imageIsSVG ? imageSrc : imageSrcWithQuality}
        alt=""
        loading="lazy"
      />
      <img
        width={width}
        height={height}
        src={hoverImageIsSVG ? imageHoverSrc : imageHoverSrcWithQuality}
        loading="eager"
        alt=""
      />
    </div>
  );
};

export default HoverImage;
